import './App.css';

import axios from 'axios';
import {
    Button,
    Space,
    Toast,
    Form,
    Input,
    Radio,
    Checkbox,
    Result,
    Modal,
    Picker,
    ErrorBlock,
} from 'antd-mobile';
import { FrownOutline } from 'antd-mobile-icons'
import { useEffect, useState } from 'react';

let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: false,
});

// axios实例拦截请求
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

// axios实例拦截响应
axiosInstance.interceptors.response.use(
    (response) => {
        // 更新token
        if (response.headers.Token) {
            localStorage.setItem('token', response.headers.Token);
        }

        // 请求成功
        if (response.status === 200) {
            if (response.data.code === 200) {
                return response;
            }

            // token失效
            // if (response.data.code === 401) {
            //     localStorage.removeItem('token');
            //     window.location.href = '/login';
            // }

            // 错误提示
            Toast.show({
                content: response.data.msg,
            });

            return Promise.reject(response);
        }

        // 状态码错误
        Toast.show({
            content: response.status,
        });

        return Promise.reject(response);
    },
    // 请求失败
    (error) => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            Toast.show({
                content: response.status,
            });

            return Promise.reject(response);
        } else {
            Toast.show({
                content: '网络连接异常，请稍后再试！',
            });
        }
    }
);

// 验证手机号
const validatePhone = (_, value) => {
    // 采用正则表达式进行校验
    const phoneRegex = /^1\d{10}/;
    // 判断 手机号 是否符合 phoneRegex 正则的校验
    if (value && !phoneRegex.test(value)) {
        // 不符合就返回错误
        return Promise.reject('手机号格式错误');
    } else {
        // 符合就返回成功
        return Promise.resolve();
    }
};

// 获取手机验证码
const getVCode = async (data) => {
    const res = await axiosInstance.post('/api/shares/sms_send', data);
    return res.data;
};
// 答题前获取人员信息
const getQuestionnaireUserInfo = async (data) => {
    const res = await axiosInstance.post('/manage/eval_analysis_answer_h5/preview_info', data);
    return res.data;
};
// 提交资料开始测评
const submitQuestionnaireUserInfo = async (data) => {
    const res = await axiosInstance.post('/manage/eval_analysis_answer_h5/start_answer', data);
    return res.data;
};
// 开始测评获取问题
const getQuestionnaireQuestion = async () => {
    const res = await axiosInstance.post('/manage/eval_analysis_answer_h5/question');
    return res.data;
};
// 结束测评
const submitQuestionnaireAnswer = async (data) => {
    const res = await axiosInstance.post('/manage/eval_analysis_answer_h5/end_answer', data);
    return res.data;
};

// 获取字典数据
const getDictData = async (data) => {
    const res = await axiosInstance.get('/api/shares/options', { params: { 'types': data } });
    return res.data;
};

// 测评超时
const expiredQuestion = async () => {
    const res = await axiosInstance.post('/manage/eval_analysis_answer_h5/expired');
    return res.data;
};

// 获取全局倒计时差
export const getGlobalCountDownSec = (sec, key) => {
    const nowTimestamp = new Date().getTime();
    const lastTimestamp = localStorage.getItem(`timer_${key}`);
    const res = sec - ((nowTimestamp - lastTimestamp) / 1000);

    return res > 0 ? Math.round(res) : 0;
};

const useGlobalCountDownSec = (sec, key) => {
    // 倒计时初始化
    const [count, setCount] = useState(getGlobalCountDownSec(sec, key));
    // 计数器更新count
    useEffect(() => {
        setTimeout(() => {
            console.log(count);
            if (count > 0) {
                setCount(getGlobalCountDownSec(sec, key));
            }
        }, 1000);
    }, [sec, count]);

    const startGlobalCount = () => {
        // 重置全局倒计时开始时间
        localStorage.setItem(`timer_${key}`, new Date().getTime());
        // 重置count
        setCount(sec);
    };

    return [count, startGlobalCount];
}

function App() {

    // 获取链接参数
    const getUrlParams = (str) => {
        try {
            var url2 = window.location.href;
            var temp2 = url2.split('?')[1];
            return temp2.split('code=')[1];
        } catch (e) {
            return null;
        }
    }

    /**
     * 0：初始化中
     * 1：用户信息表单界面
     * 2：问卷界面
     * 3：提交成功界面
     * -1: 错误界面
     * -2: 问卷超时
     */
    const [step, setStep] = useState(0);
    useEffect(() => {
        // 清除定时器
        if (step !== 2) {
            clearInterval(timer);
        }
    }, [step])

    const [error, setError] = useState('');

    const [infoDatas, setInfoDatas] = useState();

    // 初始化
    useEffect(() => {
        // 根据code获取用户信息表单和模板id
        let code = getUrlParams('code');
        if (code) {
            getQuestionnaireUserInfo({ code }).then(res => {
                infoForm.setFieldsValue(res.data);
                setInfoDatas(res.data);
                setStep(1);
            }).catch(e => {
                console.error(e);
                if (e?.data?.msg) {
                    setError(e.data.msg);
                    setStep(-1);
                }
            });
        } else {
            setError('页面参数错误');
            setStep(-1);
        }
    }, []);

    // 获取手机验证码
    const [codeLoading, setCodeLoading] = useState(false);
    const [countdown, startCountdown] = useGlobalCountDownSec(60, 'infoPhoneCode');
    const getPhoneVCode = () => {
        infoForm.validateFields([['base_field', 1, 'value']]).then(async (_) => {
            // todo
            setCodeLoading(true);

            try {
                await getVCode({ mobile: infoForm.getFieldValue(['base_field', 1, 'value']), type: 10 });
                Toast.show({
                    content: '验证码已下发到您的手机',
                });
            } catch (e) {
                console.error(e);
                if (e?.data?.msg) {
                    setError(e.data.msg);
                }
            }

            startCountdown();

            setCodeLoading(false);
        }).catch(e => {
            // pass
        });
    }

    // 提交用户信息表单
    const [infoSubmitLoading, setInfoSubmitLoading] = useState(false);
    const [infoForm] = Form.useForm();
    const onInfoSubmit = async () => {
        const values = infoForm.getFieldsValue(true);
        console.log(values);

        const result = await Modal.confirm({
            content: '提交基础信息后将开始答题，确定提交吗？',
        });
        if (!result) {
            return;
        }

        setInfoSubmitLoading(true);

        try {
            let tokenRes = await submitQuestionnaireUserInfo(values);
            localStorage.setItem('token', tokenRes.data.token);

            let res = await getQuestionnaireQuestion();
            setQuestionDatas(res.data.template);
            questionForm.setFieldsValue({
                question: res.data.template.question
            });

            timer = setInterval(() => {
                let date1 = res.data.template.begin_at;  //开始时间  
                let date2 = new Date();    //结束时间  
                let date3 = date2.getTime() - new Date(date1).getTime();   //时间差的毫秒数   

                let hours = Math.floor(date3 / (3600 * 1000));
                let leave2 = date3 % (3600 * 1000);
                let minutes = Math.floor(leave2 / (60 * 1000));
                let leave3 = leave2 % (60 * 1000);
                let seconds = Math.floor(leave3 / 1000);

                setCounter(`${hours < 10 ? ('0' + hours) : hours}:${minutes < 10 ? ('0' + minutes) : minutes}:${seconds < 10 ? ('0' + seconds) : seconds}`);

                // 超时检查
                if (res.data.template.is_time_limit) {
                    let expired = new Date(res.data.template.expired_at).getTime();
                    let now = new Date().getTime();
                    console.log(expired - now);
                    if (expired - now <= 0) {
                        clearInterval(timer);
                        expiredQuestion();
                        Modal.alert({
                            content: '答题超时，问卷已失效。',
                            onConfirm: () => {
                                setStep(-2);
                            }
                        });
                    }
                }
            }, 250);

            setStep(2);
        } catch (e) {
            console.error(e);
            if (e?.data?.msg) {
                setError(e.data.msg);
            }
        }

        setInfoSubmitLoading(false);
    }


    // 学历
    const [dictLoading, setDictLoading] = useState(true);
    const [dictVisible, setDictVisible] = useState(false);
    const [dictColumns, setDictColumns] = useState([]);

    const handleClick = async () => {
        setDictVisible(true);
        if (!dictColumns.length) {
            const data = await getDictData('sys_education');
            setDictColumns([data.data.sys_education.options.map(e => ({ label: e.name, value: e.name }))]);
            setDictLoading(false);
        }
    }

    // 用户信息表单
    const UserInfoForm = (
        <Form
            layout='vertical'
            form={infoForm}
            onFinish={onInfoSubmit}
            footer={
                <Button block type='submit' color='primary' size='large' loading={infoSubmitLoading}>
                    提交
                </Button>
            }
        >
            <Form.Header>填写测评用户信息</Form.Header>

            {
                infoDatas?.base_field.map((e, i) => {
                    if (e.field === 'age') {
                        return (
                            <Form.Item
                                name={['base_field', i, 'value']}
                                label={e.name}
                                rules={
                                    [
                                        { required: true, message: `${e.name}不能为空` },
                                        {
                                            validator: (rule, value, callback) => {
                                                if (value > 200 || value <= 0) {
                                                    callback(`请输入正确的年龄`);
                                                } else {
                                                    callback();
                                                }
                                            },
                                        },
                                    ]
                                }
                            >
                                <Input type='number' min={1} placeholder={`请输入${e.name}`} />
                            </Form.Item>
                        );
                    }

                    if (e.field === 'education') {
                        return (
                            <Form.Item
                                name={['base_field', i, 'selected_value']}
                                label={e.name}
                                trigger='onConfirm'
                                onClick={handleClick}
                                rules={[{ required: true, message: `请选择${e.name}` }]}
                            >
                                <Picker
                                    loading={dictLoading}
                                    columns={dictColumns}
                                    visible={dictVisible}
                                    onConfirm={(value) => {
                                        if (value[0]) {
                                            infoForm.setFieldValue(['base_field', i, 'value'], value[0])
                                        }
                                    }}
                                    onClose={() => {
                                        setDictVisible(false);
                                    }}
                                >
                                    {(items, { open }) => {
                                        return (
                                            <Space align='center'>
                                                {items.every(item => item === null)
                                                    ? <Input readOnly placeholder={`请选择${e.name}`} />
                                                    : <Input readOnly placeholder={`请选择${e.name}`} value={items.map(item => item?.label ?? '未选择').join(' - ')} />}
                                            </Space>
                                        )
                                    }}
                                </Picker>
                            </Form.Item>
                        );
                    }
                    return (
                        <>
                            <Form.Item
                                name={['base_field', i, 'value']}
                                label={e.name}
                                rules={
                                    e.field === "mobile"
                                        ? [
                                            { required: true, message: '手机号不能为空' },
                                            { validator: validatePhone },
                                        ]
                                        :
                                        [{ required: true, message: `${e.name}不能为空` }]
                                }
                            >
                                <Input placeholder={`请输入${e.name}`} />
                            </Form.Item>

                            {
                                e.field === "mobile" &&
                                <Form.Item
                                    name='code'
                                    label='短信验证码'
                                    extra={
                                        <Button
                                            color='primary'
                                            loading={codeLoading}
                                            disabled={countdown > 0}
                                            onClick={getPhoneVCode}
                                        >{countdown > 0 ? `${countdown}s` : '发送验证码'}</Button>
                                    }
                                    rules={[{ required: true, message: `请输入验证码` }]}
                                >
                                    <Input placeholder='请输入验证码' />
                                </Form.Item>
                            }
                        </>
                    );
                })
            }
            {
                infoDatas?.diy_field?.map((e, i) => {
                    return (
                        <Form.Item
                            name={['diy_field', i, 'value']}
                            label={e.name}
                            rules={[{ required: true, message: `${e.name}不能为空` }]}
                        >
                            <Input onChange={console.log} placeholder={`请输入${e.name}`} />
                        </Form.Item>
                    );
                })
            }
        </Form>
    );


    // 问卷表单信息
    const [questionDatas, setQuestionDatas] = useState();
    const [counter, setCounter] = useState('-');
    let timer;

    // 提交问卷表单
    const [questionLoading, setQuestionLoading] = useState(false);
    const [questionForm] = Form.useForm();
    const onQuestionSubmit = async () => {
        const values = questionForm.getFieldsValue(true);
        console.log(values);

        const result = await Modal.confirm({
            content: '确定要提交问卷吗？',
        })
        if (!result) {
            return;
        }

        setQuestionLoading(true);

        try {
            await submitQuestionnaireAnswer(values);

            setStep(3);
        } catch (e) {
            console.error(e);
            if (e?.data?.msg) {
                setError(e.data.msg);
            }
        }

        setQuestionLoading(false);
    }

    // 一页一题
    const [page, setPage] = useState(0);

    // 问卷表单
    const QuestionForm = (
        <>
            <Form
                layout='vertical'
                form={questionForm}
                onFinish={onQuestionSubmit}
                footer={
                    <Button loading={questionLoading} block type='submit' color='primary' size='large' style={{ display: questionDatas?.is_per_page !== true || (page === questionDatas?.question.length - 1) ? 'block' : 'none' }}>
                        提交
                    </Button>
                }
            >
                <Form.Header>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <span style={{ color: 'red' }}>计时中：</span><span style={{ color: 'black' }}>{counter}</span>
                        </div>
                        {
                            questionDatas && questionDatas.is_time_limit &&
                            <div style={{ color: 'red' }}>限时：{questionDatas.limit_duration}{questionDatas.limit_unit === 1 ? '分钟' : '小时'}</div>
                        }
                    </div>
                </Form.Header>

                {
                    questionDatas?.question.map((quiz, quizIndex) => {
                        // 一题一页模式
                        if (questionDatas.is_per_page && page !== quizIndex) {
                            return;
                        }

                        return quiz.answer_type === 1
                            ? <Form.Item
                                name={['question', quizIndex, 'selected_index']}
                                label={quiz.content + '（单选题）'}
                                rules={[{ required: true, message: `请选择答案` }]}
                            >
                                <Radio.Group onChange={(value) => {
                                    let answers = questionForm.getFieldValue(['question', quizIndex, 'answer']);
                                    answers.forEach(e => {
                                        if (e.id === value) {
                                            e.is_selected = true;
                                        } else {
                                            e.is_selected = false;
                                        }
                                    });
                                    questionForm.setFieldValue(['question', quizIndex, 'answer'], answers);
                                }}>
                                    <Space direction='vertical'>
                                        {
                                            quiz.answer.map(item => {
                                                return (
                                                    <Radio value={item.id}>{`${item.answer_key}. ${item.answer_value}`}</Radio>
                                                );
                                            })
                                        }
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            : <Form.Item
                                name={['question', quizIndex, 'selected_index']}
                                label={quiz.content + '（多选题）'}
                                rules={[{ required: true, message: `请选择答案` }]}
                            >
                                <Checkbox.Group onChange={(value) => {
                                    let answers = questionForm.getFieldValue(['question', quizIndex, 'answer']);
                                    answers.forEach(e => {
                                        if (value.includes(e.id)) {
                                            e.is_selected = true;
                                        } else {
                                            e.is_selected = false;
                                        }
                                    });
                                    questionForm.setFieldValue(['question', quizIndex, 'answer'], answers);
                                }}>
                                    <Space direction='vertical'>
                                        {
                                            quiz.answer.map(item => {
                                                return (
                                                    <Checkbox value={item.id}>{`${item.answer_key}. ${item.answer_value}`}</Checkbox>
                                                );
                                            })
                                        }
                                    </Space>
                                </Checkbox.Group>
                            </Form.Item>;
                    })
                }

                {
                    questionDatas?.is_per_page &&
                    <div style={{ display: 'flex' }}>
                        {
                            page !== 0 &&
                            <Button block color='primary' fill='outline' style={{ margin: 10 }} onClick={() => {
                                setPage(data => data - 1);
                            }}>
                                上一题
                            </Button>
                        }
                        {
                            (page !== questionDatas?.question.length - 1) &&
                            <Button block color='primary' fill='solid' style={{ margin: 10 }} onClick={() => {
                                questionForm.validateFields([['question', page, 'selected_index']]).then(value => {
                                    setPage(data => data + 1);
                                }).catch(e => {
                                    // pass
                                });
                            }}>
                                下一题
                            </Button>
                        }
                    </div>
                }
            </Form>
        </>

    );

    return (
        <div>
            {
                step === -1 &&
                <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ErrorBlock status='default' title={error} description="出现错误" />
                </div>
            }
            {
                step === 0 &&
                <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ErrorBlock status='busy' title="正在加载数据" description="请稍等" />
                </div>
            }
            {
                step === 1 && UserInfoForm
            }
            {
                step === 2 && QuestionForm
            }
            {
                step === 3 &&
                <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Result
                        status='success'
                        title='问卷已提交'
                        description='问卷已成功提交，您可自行关闭页面。'
                    />
                </div>
            }
            {
                step === -2 &&
                <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Result
                        icon={<FrownOutline />}
                        status='error'
                        title='答题超时'
                        description='答题时间超过设定上限，问卷已失效！'
                    />
                </div>
            }
        </div>
    );
}

export default App;
